import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import Vue from 'vue';
// @ts-ignore
import removeLoader from '@/mixins/removeLoader';
// @ts-ignore
import App from '@/components/pages/App';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './assets/scss/app.scss';
// @ts-ignore
import enums from 'vue-enums';
import posthogPlugin from '@/utilities/posthog';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import en from '@superthread-com/localization/languages/en';
import { changeLanguage } from '@/utilities/languages';

import vClickOutside from 'v-click-outside';
import VueVirtualScroller from 'vue-virtual-scroller';

if (!['dev', 'local'].includes(`${process.env.VUE_APP_ENV}`)) {
  let sentryInited = false;
  const captureError = async (error: any) => {
    const { logError, initSentry } = await import(
      /* webpackChunkName: "sentry" */ './utilities/sentry'
    );
    if (!sentryInited) {
      sentryInited = true;
      initSentry(router);
    }
    logError(error);
  };
  window.onerror = (message, url, line, column, error) => captureError(error);
  window.onunhandledrejection = (event: any) => captureError(event?.reason);
}

Vue.use(vClickOutside);
Vue.use(enums);
Vue.use(VueVirtualScroller);
Vue.use(posthogPlugin);
Vue.mixin(removeLoader);

Vue.config.productionTip = false;

Vue.use(I18NextVue, { i18next });

i18next.on('initialized', () => {
  Vue.prototype.translate = (key: string, interpolations: Record<string, string> = {}) => {
    return i18next.t(key, { ...interpolations, interpolation: { escapeValue: false } });
  };
  Vue.prototype.changeLanguage = (language: string) => changeLanguage(i18next, language);

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');

  // @ts-ignore
  window.StVueApp = app;
});

i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en', 'fr', 'bs'],
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
});
