export default {
  computed: {
    isProject() {
      return (this.$route.name === 'boards' || this.$route.name === 'pages') && !this.inEpicBoard;
    },

    notificationTypePage() {
      return this.$store.getters.getCurrentInboxResource.resource_type === 'page';
    },

    isPage() {
      return this.$route.name === 'page' && !this.inEpicBoard;
    },
    isBoard() {
      return this.$route.name === 'board' && !this.inEpicBoard;
    },
    isSprints() {
      return this.$route.name === this.$constants.routeNames.sprintsRouteName && !this.inEpicBoard;
    },
    isSprint() {
      return this.$route.name === this.$constants.routeNames.sprint && !this.inEpicBoard;
    },
    isNewPage() {
      return this.$route.name === 'newpage' && !this.inEpicBoard;
    },
    isNewBoard() {
      return this.$route.name === 'newboard' && !this.inEpicBoard;
    },
    isAnyCardDisplayed() {
      return this.$store.getters.getIsAnyCardDisplayed || this.$store.getters.getChildCardCreation;
    },
    isManageSpaces() {
      return this.$route.name === this.$constants.manageSpacesRouteName && !this.inEpicBoard;
    },
    isEpicsBoard() {
      return this.$route.name === this.$constants.epicsBoardRoute.name && !this.inEpicBoard;
    },
    membersBasedOnRoute() {
      if (this.isManageSpaces) {
        const selectedProjectId = this.$store.getters.getSelectedProjectId;
        return selectedProjectId !== ''
          ? this.$store.getters.getTeamProjectById(selectedProjectId)?.members
          : [];
      }
      if (this.isProject || this.isPage) {
        const inactiveMembers = this.$store.getters.getInactiveTeamMembers;
        return Object.values(
          this.$store.getters.getCurrentMembers.filter((member) => !inactiveMembers[member.user_id])
        );
      }
      if (this.isBoard) {
        return this.$store.getters.currentBoard.members;
      }
      return [];
    },

    isInbox() {
      return this.$route.name === this.$constants.routeNames.inbox && !this.inEpicBoard;
    },
  },
};
