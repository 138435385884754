/**
 * Main vuex getters
 * @packageDocumentation
 * @category Store
 */
import { GetterTree } from 'vuex';
import { RootState as State, User, Team, Project } from '@/types';
import sortProjects from '@/utilities/sortProjects';
import constants from '@/utilities/constants';
import { TeamMember } from '@superthread-com/api';
import { ResourceType } from '@/types/resources';
import { MyWorkTabId } from '@/types/tabs';

const getters: GetterTree<State, any> = {
  /** Returns the team sub domain of the currently logged in user. */
  getTeamSubDomain: (state): string => state.loginDetails.teamSubDomain,
  /** Returns the username of the currently logged in user. */
  getUserName: (state): string => state.loginDetails.userName,
  /** Returns the email of the currently logged in user. */
  getEmail: (state): string => state.loginDetails.email,
  /** Returns the currently logged in user object. */
  getUser: (state): User => state.user,
  /** Returns the user id. */
  getUserId: (state): string => state.user.id,
  /** Returns the user email. */
  getUserEmail: (state): string => state.user.email,
  /** Returns the team of the user. */
  getTeam: (state, _getters, rootState, rootGetters): Team => {
    const teamId = rootGetters.getTeamID;
    return (state.user.teams || []).find((t: Team) => t.id === teamId);
  },
  getUserTeams: (state): Team[] => {
    return state.user.teams;
  },
  /** Returns the password of the currently logged in user. */
  getPassword: (state): string => state.loginDetails.password,
  /** Returns flag determing if user is logged in or not. */
  getLoggedIn: (state): boolean => state.loggedIn,
  /** Returns project where user is member. */
  getProjects: (state): Project[] => state.teamProjects.filter((p: any) => p.is_member) || [],
  /** Returns my projects sorted by project_order array. */
  getMySortedProjects: (state, _getters): any[] => {
    return sortProjects(_getters.getProjects, _getters.getProjectOrder);
  },
  /** Returns my project for given id */
  getMyProjectById: (state, _getters) => (id: string) =>
    _getters.getProjects.find((p: any) => p.id === id),
  /** Returns team project for given id */
  getTeamProjectById: (state) => (id: string) => state.teamProjects.find((p: any) => p.id === id),
  /** Returns all project from user's team. */
  getTeamProjects: (state): Project[] => state.teamProjects || [],
  getTeamSortedProjects: (state, _getters): any[] => {
    return sortProjects(_getters.getTeamProjects, _getters.getProjectOrder);
  },
  socketDeletedProjects: (state): string[] => state.socketDeletedProjects,
  /** Returns order of projects. */
  getProjectOrder: (state): string[] => state.projectOrder ?? [],
  /** Returns project based on passed project id */
  getProjectById: (state, _getters) => (id: string) => _getters.getTeamProjectById(id) || {},
  /** Returns current comments. */
  getComments: (state): any[] => state.comments,
  /** Returns cards comments and activities */
  getCardCommentsAndActivities: (state, globalGetters) => (id: string) => {
    const activities = state.cardsCommentsAndActivities[id];
    if (activities) {
      return globalGetters.getCommentActivityOrderOfDisplayedCard(id) === 'newest'
        ? [...activities].sort((a: any, b: any) => b.time_created - a.time_created)
        : [...activities].sort((a: any, b: any) => a.time_created - b.time_created);
    }
    return [];
  },
  /** Returns comments for page. */
  getPageComments: (state) => state.comments.sort((a, b) => a.time_created - b.time_created),
  /**
   * Returns comment object for passed comment id.
   * @param {string} id comment id to look for.
   */
  getCommentById: (state) => (id: string, cardId: string) => {
    if (cardId)
      return state.cardsCommentsAndActivities[cardId]?.find((c: any) => c.id === id) || {};
    else return state.comments.find((c: any) => c.id === id) || {};
  },
  /** Returns the id of the comment which is currently edited. */
  getCurrentEditCommentId: (state): string => state.currentEditCommentID,
  /** Returns the previous route object. */
  getPreviousRoute: (state): object => state.previousRoute,
  /** Returns the previous route name */
  getPreviousRouteName: (state): string => state.previousRoute.name || '',
  /** Returns the prevoious route path */
  getPreviousRoutePath: (state): string => state.previousRoute.path || '',
  /** Returns notification message for popup. */
  getPopUpMessage: (state): string => state.popUpMessage,
  /** Returns the flag which controls the display of dropdown for adding/browsing projects. */
  getProjectSidebarDropdown: (state) => state.projectSidebarDropdown,
  /** Returns the flag which controls the display of popup for help section. */
  getHelpPopupIsOn: (state): boolean => state.helpPopupIsOn,
  /** NOT IN USE */
  getCommentListOrderBy: (state): string => state.commentListOrderBy,
  /** Returns the id of the adjecent project (prioritizes previous one) of the project passed as param */
  getAdjacentProjectId:
    (state, getters) =>
    (projectId: string = state.currentProjectId) => {
      const order = getters.getProjectOrder;

      // last project in the list -> navigate to a fallback route (e.g. manage spaces)
      if (order.length === 1) return '';

      const index = order.indexOf(projectId);
      // does not exist in project order
      if (index === -1) return '';

      const previousProjectIndex = index === 0 ? index + 1 : index - 1;
      return order[previousProjectIndex];
    },
  /** Returns the id of the currently selected project. */
  getCurrentProjectId: (state): string => state.currentProjectId,
  /** Returns current project object */
  getCurrentProject: (state, _getters) => _getters.getProject(state.currentProjectId) ?? {},
  /** Returns the project object for given project id from teamProject or trashProjects. */
  getProject:
    (state, _getters) =>
    (projectId: string = state.currentProjectId) => {
      return _getters.getTeamProjectById(projectId) ?? _getters.getTrashProjectById(projectId);
    },
  /** Returns a title of the current project */
  getCurrentProjectTitle: (state, _getters): string => _getters.getCurrentProject.title || '',
  /** Returns the description of the current project. */
  getCurrentProjectDescription: (state, _getters): string =>
    _getters.getCurrentProject.description || '',
  /** Returns the project name on the form for adding project. */
  getProjectFormName: (state): string => state.projectFormName,
  /** Returns the project description on the form for adding new project. */
  getProjectFormDescription: (state): string => state.projectFormDescription,
  /** Returns a flag controling the display of page comments list.  */
  getCommentsAreActive: (state): boolean => state.commentsAreActive,
  /** Returns a flag to determin where does a comment belong page or card. */
  getCommentsSection: (state) => state.commentsSection,
  /** Returns environment specific endpoints */
  getRoutes: (state) => {
    if (process.env.VUE_APP_ENV) {
      return state.routes[process.env.VUE_APP_ENV];
    }
    return state.routes.dev;
  },
  /** Returns current tab name (Pages or Boards) */
  getCurrentTabName: (state) => state.currentTabName,
  /** Returns value to show login message (notification). */
  loginActionStatus: (state) => state.loginActionStatus,
  /** Returns members which are in the project. */
  getCurrentMembers: (state) => state.members,
  /** Get member by id */
  getMemberById: (state) => (id: any) => state.teamMembers[id] || state.inactiveMembers[id] || {},
  /** Returns a flag to show or hide search popup.  */
  showSearchModal: (state): boolean => state.showSearchModal,
  /** Returns value to show search result on search popup. */
  searchResults: (state) => state.searchResults,
  /** Returns value to show search result in child search dropdown. */
  childSearchResults: (state) => state.childSearchResults,
  /** Returns value of last search */
  getLastSearchQuery: (state) => state.lastSearchQuery,
  /** Returns team slug for logged user's team  */
  getTeamSlug: (state, globalGetters) => {
    const currentTeam = (state.user.teams || []).find((t: any) => t.id === globalGetters.getTeamID);
    return currentTeam?.sub_domain || localStorage.getItem('teamSubDomain') || '';
  },
  /** Returns notification message to be displayed */
  getNotificationMessage: (state) => state.notificationMessage,
  /** Returns notification type to be displayed */
  getNotificationType: (state) => state.notificationType,
  /** Returns array of team sub domains */
  getTeamSubDomains: (state) => state.loginDetails.teamSubDomains,
  /** Returns dark mode flag */
  darkMode: (state) => state.darkMode,
  /** Returns name of setted background for boardView */
  getBoardsBackground: (state) => state.boardsBackground,
  /** Returns the flag that indicates is sidebar expanded. */
  sidebarExpanded: (state): boolean => state.sidebarExpanded,
  /** Returns value that indicates is popup for adding new member opened */
  getAddMemberPopupIsOn: (state): boolean => state.addMemberPopupIsOn,
  /** Returns id that indicates which dropdown for adding members is active */
  getDisplayMembersDropdownId: (state) => state.displayMembersDropdownId,
  /** Returns object that indicates in which item to add members(board, page, card, project) */
  getAddMembersTarget: (state): object => state.addMembersTarget,

  getDisplayEditLinkPopup: (state) => state.displayEditLinkPopup,
  getDisplayEditorTooltip: (state) => state.displayEditorTooltip,
  getCommentLinkUrl: (state) => state.commentLinkUrl,
  getCommentLinkText: (state) => state.commentLinkText,
  /** Returns value to show or hide popup for edit user profile. */
  displayInfoSidebar: (state) => state.displayInfoSidebar,

  getTeamMembers: (state) => state.teamMembers,

  getInactiveTeamMembers: (state) => state.inactiveMembers,

  getDefaultTabName: (state) => {
    const currentProjecId = state.currentProjectId;
    const currentProjectPath = `${constants.projectSlug}-${currentProjecId}`;
    const currentTabName = state.currentTabName || 'boards';
    const { teamSubDomain } = state.loginDetails;
    if (!currentProjecId) {
      return `/${teamSubDomain}/${constants.manageSpacesRouteName}`;
    }
    return `/${teamSubDomain}/${currentProjectPath}/${currentTabName}`;
  },
  getRedirectRouteAfterLogin: (state) => state.redirectRouteAfterLogin,
  /** Returns notification count. */
  notificationCount: (state) => state.notificationCount,
  getNotificationAlerts: (state) => state.notificationAlerts,
  /** Returns team member notifications. */
  getTeamMemberNotifications: (state) => state.teamMemberNotifications,
  getPublicViews: (state) => state.publicViews,
  getPrivateViews: (state) => state.privateViews,
  getSelectedViewType: (state) => state.selectedViewType,
  getViewResults: (state) => state.viewPreview,
  getViewResultsFromList: (state) => (viewId: string | number) =>
    state.viewResultsList[viewId] || {},
  getListOfViewResults: (state) => state.viewResultsList,
  getSelectedView: (state) => state.selectedView,
  getViewFilters: (state) => state.viewFilters,
  getViewPills: (state) => state.viewPills,
  getViewEditMode: (state) => state.viewEditMode,
  getBoardsFilters: (state) => state.boardsFilters,
  getEmojiPickerDisplayed: (state) => state.emojiPickerDisplayed,
  /** Returns ui toast notifications array */
  getUiNotifications: (state) => state.uiNotifications,
  getFavouritesOpened: (state) => state.favouritesOpened,
  getOpenedFavouriteItem: (state) => state.openedFavouriteItem,
  isFavourite: (state) => (id: string, type: string) => {
    return state.favourites.some(
      (fav) =>
        (fav?.resource_id === id && fav?.resource_type === type) ||
        fav?.children?.some(
          (child: any) => child?.resource_id === id && child?.resource_type === type
        )
    );
  },
  getMembersSearchInput: (state) => state.membersSearchInput,
  getHeaderMemberDropdownPosition: (state): any => state.headerMemberDropdownPosition,
  activeComment: (state) => state.activeComment,
  getCurrentRoute: (state) => state.currentRoute,
  getSocketConnected: (state) => state.socketConnected,
  getDisplayVideoLinkPopup: (state) => state.displayVideoLinkPopup,
  getIntegrationInstallations: (state) => (integration: string) =>
    state.installations[integration] || [],
  getImportProgressSteps: (state) => state.importProgressSteps,
  getRouteBeforeSettings: (state) => state.routeBeforeSettings,
  getImportProgressCurrentStep: (state) => state.importProgressCurrentStep,
  getSettingsCurrentProjectId: (state): string => state.settingsCurrentProjectId,
  getSettingsCurrentBoardId: (state): string => state.settingsCurrentBoardId,
  getIntegrationSettings: (state) => state.integrationSettings,
  getProjectExpanded: (state) => (id: number) => state.projectSidebarExpanded[id] || false,
  getProjectsExpanded: (state) => state.projectsExpanded,
  getFavouritesExpanded: (state) => state.favouritesExpanded,
  draggingEntityProjectFromId: (state) => state.draggingEntityProjectFromId,
  draggingSidebarItem: (state) => state.draggingSidebarItem,
  getSelectedProject: (state, _getters) =>
    _getters.getTeamProjectById(state.selectedProjectId) || {},
  getSelectedProjectTitle: (state, _getters): string => _getters.getSelectedProject.title || '',
  getSelectedProjectDescription: (state, _getters): string =>
    _getters.getSelectedProject.description || '',
  getSelectedProjectId: (state): string => state.selectedProjectId,
  getSdProjectSettingsDropdown: (state): string => state.sdProjectSettingsDropdown,
  getSdProjectNewPageBoardDropdown: (state): string => state.sdProjectNewPageBoardDropdown,
  getSdPageSettingsDropdown: (state): string => state.sdPageSettingsDropdown,
  getSdBoardSettingsDropdown: (state): string => state.sdBoardSettingsDropdown,
  getSdUserActionsDropdown: (state) => state.sdUserActionsDropdown,

  getPendingInvites: (state) => state.pendingInvites,
  getCurrentRole: (state, globalGetters) => {
    const currentTeam = state.teams[globalGetters.getTeamID];
    return currentTeam?.role || constants.roles.member;
  },
  getDoubleCheckPopup: (state) => state.doubleCheckPopup,
  getConfirmationModal: (state) => state.confirmationModal,
  getPreviewAttachmentIndex: (state) => state.previewAttachmentIndex,
  getPreviewAttachments: (state) => state.previewAttachments,
  getAttachmentPreviewFromInput: (state) => state.attachmentPreviewFromInput,
  getColorsPopup: (state) => state.colorsPopup,
  getCommentsOrder: (state) => state.commentsOrder,
  getQuickCardIsOn: (state) => state.quickCard,
  quickCardBoardId: (state) => state.quickCardBoardId,
  getQuickPage: (state) => state.quickPage,
  getCardCommentActivityFilter: (state) => state.cardCommentActivityFilter,
  getCommentsInProgressPatch: (state) => state.commentsInProgressPatch,
  getCommentsInProgressDelete: (state) => state.commentsInProgressDelete,
  getOnline: (state) => state.online,
  getLoadedFiles: (state) => state.loadedFiles,
  getProjectPreviewLastScrollPos: (state) => (projectId: string) =>
    state.projectPreviewLastScrollPos[projectId] || 0,
  getFavouriteResource: (state) => (index: number) => state.favourites[index] || {},
  getFavourites: (state) => state.favourites || [],
  favouritesStettingsDropdown: (state) => state.favouritesStettingsDropdown,
  getFavouritesFolderExpanded: (state) => (id: string) =>
    state.favouritesFolderExpanded[id] || false,
  newFolderInput: (state) => state.newFolderInput,
  getImportHistory: (state) => state.importHistory,
  favouriteFolderForRename: (state) => state.favouriteFolderForRename,
  favouriteFolderTitle: (state) => state.favouriteFolderTitle,
  getViewItemSettingsDropdown: (state) => state.viewItemSettingsDropdown,
  showPeekSidebar: (state) => state.showPeekSidebar,
  getTheme: (state) => state.theme,
  getElectronBrowseHistory: (state) => state.electronBrowseHistory,
  getElectronBrowseHistoryIndex: (state) => state.electronBrowseHistoryIndex,
  getElectronRecentlyViewed: (state, getters) => {
    return state.electronRecentlyViewed.map((item: any) => ({
      ...item,
      label: getters.resourceTitle(item.type, item.id) || item.label,
    }));
  },
  getThemePreference: (state) => state.themePreference || 'matchSystem',
  getInviteeRole: (state) => state.inviteeRole,
  getInviteesProjectIds: (state) => state.inviteesProjectIds,
  getRedirectProjectId: (state) => state.redirectProjectId,
  getCurrentInboxResource: (state) => state.currentInboxResource,
  inboxFilters: (state) => state.inboxFilter,
  getInboxListWidth: (state) => state.inboxListWidth,
  inboxListExpanded: (state) => state.inboxListExpanded,
  getShowOnboarding: (state) => state.showOnboarding,
  getIconPickerDropdown: (state) => state.iconPickerDropdown,
  getManageProjectsItemSettingsDropdown: (state) => state.manageProjectsItemSettingsDropdown,
  getOpenInDesktopApp: (state) => state.openInDesktopApp,
  getPreviewAttachmentId: (state) => state.previewAttachmentId,
  getPreviewAttachmentResourceId: (state) => state.previewAttachmentResourceId,
  getCardIdForLocalCardId: (state) => (localId: string) => state.localCardIds[localId] ?? '',
  getLocalCardIdForId: (state) => (id: string) => {
    const localId = Object.keys(state.localCardIds || []).find(
      (key) => state.localCardIds[key] === id
    );
    return localId ?? '';
  },
  getShowRedirectedToAppOverlay: (state) => state.showRedirectedToAppOverlay,
  getMembersDropdownInvitees: (state) => state.membersDropdownInvitees,
  editorForCardLink: (state) => state.editorForCardLink,
  getMyWorkSelectedTabId: (state) => (userId: string) =>
    state.myWorkSelectedTabId[userId] || MyWorkTabId.Assigned,
  getMyWorkUserId: (state) => state.myWorkUserId,
  getManageMembersItemSettingsDropdown: (state) => state.manageMembersItemSettingsDropdown,
  getMemberHoverPreview: (state) => state.memberHoverPreview,
  getInfoSidebarMemberItemSettingsDropdown: (state) => state.infoSidebarMemberItemSettingsDropdown,
  getDeletePopupId: (state) => state.deletePopupId,
  getItemNotFound: (state) => state.itemNotFound,
  getTrashProjects: (state) => state.trashProjects || [],
  getTrashProjectById: (state) => (id: string) => state.trashProjects.find((p: any) => p.id === id),
  getMemberHoverPreviewInstances: (state) => state.memberHoverPreviewInstances,
  getBoardsColumnLayout: (state, _getters) => (key: string) =>
    state.boardsColumnLayout[`${_getters.getTeamSlug}-${key}`] || 'list',
  getPagesColumnLayout: (state, _getters) => (key: string) =>
    state.pagesColumnLayout[`${_getters.getTeamSlug}-${key}`] || 'list',
  getCurrentProjectBoardColumnLayout: (state, _getters) =>
    state.boardsColumnLayout[`${_getters.getTeamSlug}-${state.currentProjectId}`] || 'list',
  getCurrentProjectPageColumnLayout: (state, _getters) =>
    state.pagesColumnLayout[`${_getters.getTeamSlug}-${state.currentProjectId}`] || 'grid',
  getPressedEscCancelDragging: (state) => state.pressedEscCancelDragging,
  getEstimateSystemForProject: (state, _getters) => (projectId: string) =>
    _getters.getProjectById(projectId)?.estimate_system || '',
  getCurrentViewTotalCount: (state) => state.currentViewTotalCount,
  sidebarHover: (state) => state.sidebarHovered,
  getSidebarDragging: (state) => state.sidebarDragging,
  getTeamFeatureEnabled: (state) => (featureId: string) => {
    return state.teamFeatures.find((feature: any) => feature.id === featureId)?.enabled || false;
  },
  getTeamFeatureAvailable: (state) => (featureId: string) => {
    return state.teamFeatures.some((feature: any) => {
      return feature.id === featureId;
    });
  },
  getTeamFeature: (state) => (featureId: string) => {
    return state.teamFeatures.find((feature: any) => feature.id === featureId) || {};
  },
  getTeamLimit: (state) => (limitId: string) => {
    return state.teamLimits.find((limit: any) => limit.id === limitId) || {};
  },
  getUserFeatureEnabled: (state, _getters, _rootState, rootGetters) => (featureId: string) => {
    return state.teams[rootGetters.getTeamID]?.flags?.includes?.(featureId);
  },
  getSubscriptionPlan: (state, rootGetters) => {
    return state.teams[rootGetters.getTeamID]?.subscription_plan_id;
  },
  getShowUpdateEmailPopup: (state) => state.showUpdateEmailPopup,
  showHideReplies: (state) => state.showHideReplies,
  editorOpen: (state) => state.editorOpen,
  getPageIconPickerDisplayed: (state) => state.pageIconPickerDisplayed,
  getHideEmptyGroups:
    (state, getters) =>
    (boardId = getters.currentBoardId) =>
      state.hideEmptyGroups[boardId] || false,
  getShowWeekends: (state) => state.showWeekends,
  getReconnectionCallbacks: (state) => state.reconnectionCallbacks,
  getPreviousNextButtonClicked: (state) => state.previousNextButtonClicked,
  resolveInlineComment: (state) => state.resolveInlineComment,
  getUserTimezoneId: (state) => state.user.timezone_id,
  getUserLocale: (state) => state.user.locale,
  getNotificationDigest: (state) => state.notificationDigest || {},
  getNotificationDigestSettings: (state) => state.notificationDigestSettings || {},
  getCachedComponents: (state) => state.cachedComponents,
  getHeaderProjectSettingsDropdown: (state) => state.headerProjectSettingsDropdown,
  getCardsLayoutOptionsDropdown: (state) => state.cardsLayoutOptionsDropdown,
  getShowTemplatesDropdown: (state) => state.showTemplatesDropdown,
  getBoardEmbedPopup: (state) => state.boardEmbedPopup,
  getIsSettingsOpen: (state) => state.isSettingsOpen,
  getShowUpgradePlanPopup: (state) => state.showUpgradePlanPopup,
  getMyWorkSortBy: (state) => state.myWorkSortBy,
  showCardsWithNoSprintSidebar: (state) => state.showCardsWithNoSprintSidebar,
  getModal: (state) => state.modal || {},
  getIsModalDisplayed: (state) => Object.keys(state.modal || {}).length > 0,
  resourceTitle:
    (state, globalGetters) => (resourceType: ResourceType | string, resourceId: string) => {
      // based on resource type, get the title of the resource
      switch (resourceType) {
        case ResourceType.Page:
          return globalGetters.getPages[resourceId]?.title || '';
        case ResourceType.Card:
        case constants.routeNames.boardCard:
          return globalGetters.getCardById(resourceId).title || '';
        case ResourceType.Board:
          return globalGetters.getBoardById(resourceId).title || '';
        case ResourceType.Project:
        case ResourceType.Space:
        case constants.routeNames.space:
          return globalGetters.getProjectById(resourceId).title || '';
        case ResourceType.View:
          return globalGetters.getViewById(resourceId).title || '';
        default:
          return '';
      }
    },
  getWorkspaceOwner: (state) => {
    const members = state.teamMembers;
    const teamOwner = Object.values(members as Record<string, TeamMember>).find(
      (member) => member.role === constants.roles.owner
    );

    return teamOwner ?? {};
  },
  getRecentWorkspaces: (state) => state.recentWorkspaces,
  getOpenWorkspaceThroughQuickSwitcher: (state) => state.openWorkspaceThroughQuickSwitcher,
  getSidebarSprintsExpanded: (state) => state.sidebarSprintsExpanded,
};

export default getters;
